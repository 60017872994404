import axios from 'axios'
import { Notification } from 'element-ui'

const KmsxServiceAdapter = axios.create({
    baseURL: `${process.env.VUE_APP_API_KMSX}/v1/`,
    timeout: 100000
})

KmsxServiceAdapter.interceptors.response.use(response => {
    return response
}, error => {
    if (error.code === 'ECONNABORTED' || error.response.status === 408) {
        Notification.warning({
            title: "Problema de conexão!",
            message: "O tempo máximo de pedido de 1m foi excedido.",
        })
    }
    return Promise.reject(error)
})

export default KmsxServiceAdapter