import ServiceAdapter from "@/services/KFisServiceAdapterV1.js";
import KmsxServiceAdapter from "./KmsxServiceAdapter.js";
import AuthUtils from "../utils/AuthUtils";
import icon from "../assets/logo.png"

import { AxiosResponse } from 'axios'

const KonnectEnterpriseService = {
    // GET
    getClientes: ({ offset, limit, ffield, order, usaPaginacao = true, filterFields = null, filterValue = null }) => {
        var filter = filterFields != "" && filterValue != "" ? `&filterFields=${filterFields}&filterValue=${filterValue}` : ``;
        return ServiceAdapter.get(`clientes?usaPaginacao=${usaPaginacao}&offset=${offset * limit}&limit=${limit}&ffield=${ffield}&order=${order}${filter}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    getCanais: tipo => {
        return KmsxServiceAdapter.get(`canais/${tipo}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        })
    },

    marcarComoVisto: id => {
        return KmsxServiceAdapter.patch(`canais/${id}/vistoPorUltimo`, {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    getCanalPrivado: id => {
        return KmsxServiceAdapter.get(`canais/privados/com-usuario/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    // POST
    postMembros: (data, idMembros) => {
        return KmsxServiceAdapter.post(`canais/${data.tipo}s/${data._id}/membros`, idMembros, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    postCanal: (tipo, reqBody) => {
        return KmsxServiceAdapter.post(`canais/${tipo}`, reqBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    postMensagem: (reqBody, id) => {
        return KmsxServiceAdapter.post(`canais/${id}/mensagens`, reqBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    // DELETE
    deleteMembro: (data, idUsuario) => {
        return KmsxServiceAdapter.delete(`canais/${data.tipo}s/${data._id}/membros/${idUsuario}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    deleteCanal: data => {
        return KmsxServiceAdapter.delete(`canais/${data.tipo}s/${data._id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    //PATCH
    concluirEvento: id => {
        return KmsxServiceAdapter.patch(`canais/eventos/${id}/data-conclusao`, null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    uploadImagem: (data, reqBody) => {
        return KmsxServiceAdapter.patch(`canais/${data.tipo}s/${data._id}/imagem`, reqBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    //update para membros - no momento só é usado para atualizar a tag admin dentro de membros do canal
    patchMembro: (data, idUsuario, reqBody) => {
        return KmsxServiceAdapter.patch(`canais/${data.tipo}s/${data._id}/membros/${idUsuario}`, reqBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    patchCanal: (reqBody, data) => {
        return KmsxServiceAdapter.patch(`canais/${data.tipo}s/${data._id}`, reqBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + AuthUtils.getToken()
            }
        });
    },

    // OUTROS
    notificarNovaMsg: (conteudo, chat) => {
        const audio = new Audio(require("../assets/notify.mp3"));
        audio.play();
        if (Notification.permission === "granted") {
            let options = {
                body: conteudo,
                icon: icon,
                silent: true,
                renotify: false
            }
            new Notification(chat, options);
        }
    },

    /**
     * @typedef {Object} Msg
     * @property {String} _id
     * @property {String} pk
     * @property {String} canalId
     * @property {Msg} mensagemPai
     * @property {Number} remetenteId
     * @property {String} tipoMensagem
     * @property {String} subtipoMensagem
     * @property {String} mimeTypeMensagem
     * @property {*} conteudo
     * @property {Object} metadata
     * @property {Array} baixadoPor
     * @property {Date} dataDeEnvio
     * @property {Boolean} deletado
     * 
     * @typedef {Object} PkIndexModel
     * @property {Number} pkIndex
     * 
     * @typedef {Msg & PkIndexModel} MsgComPkIndex
     */

    /**
     * Busca as primeiras mensagens de um pk de mensagens de um canal
     * 
     * (Pks são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     * 
     * @param {String} canalId Id do canal
     * @param {Number} msgsPkIndex Pk Index a pegar as primeiras mensagens
     * @returns {Promise<AxiosResponse<Array<Msg>>}
     */
    mensagensPkGetPrimeiras: (canalId, msgsPkIndex) => KmsxServiceAdapter.get(`canais/${canalId}/mensagens/pk-index/${msgsPkIndex}/primeiras`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }
    }),

    /**
     * Busca as ultimas mensagens de um PK de mensagens de um canal
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     * 
     * @param {String} canalId Id do canal
     * @param {Number} msgsPkIndex Pk Index a pegar as primeiras mensagens
     * @returns {Promise<AxiosResponse<Array<Msg>>}
     */
    mensagensPkGetUltimas: (canalId, msgsPkIndex, quantidadeDeMensagens) => KmsxServiceAdapter.get(`canais/${canalId}/mensagens/pk-index/${msgsPkIndex}/ultimas`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }, 
        params: {
            quantidadeMensagens: quantidadeDeMensagens
        }
    }),

    /**
     * Recupera as mensagens que vieram antes de um mensagem em uma pk de um canal
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     *
     * @param {String} canalId Id do canal
     * @param {Number} pkIndex 
     * @param {String} msgId 
     * @returns {Promise<AxiosResponse<Array<Msg>>}
     */
    mensagensPkGetAntesDaMsg: (idCanal, pkIndex, msgId = null) => KmsxServiceAdapter.get(`canais/${idCanal}/mensagens/pk-index/${pkIndex}/antes-da-msg/${msgId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }
    }),

    /**
     * Recupera as mensagens que vieram depois de um mensagem em uma pk de um canal
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     *
     * @param {String} idCanal 
     * @param {Number} pkIndex 
     * @param {String} msgId 
     * @returns {Promise<AxiosResponse<Array<Msg>>} 
     */
    mensagensPkGetDepoisDaMsg: (idCanal, pkIndex, msgId) => KmsxServiceAdapter.get(`canais/${idCanal}/mensagens/pk-index/${pkIndex}/depois-da-msg/${msgId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }
    }),

    /**
     * Recupera as mensagens do tipo "file" em uma pk de um canal
     * 
     * Se uma mensagem for passada, será buscada as mesagens ANTES da mensagem informada
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     * 
     * @param {string} idCanal 
     * @param {number} pkIndex 
     * @param {string?} antesDaMensagemId 
     * @returns {Promise<AxiosResponse<Array<Msg>>}
     */
    mensagensPkGetFiles: (idCanal, pkIndex, antesDaMensagemId = null) => KmsxServiceAdapter.get(`/canais/${idCanal}/mensagens/pk-index/${pkIndex}/arquivos`, {
        params: {
            antesDaMensagem: antesDaMensagemId,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }
    }),

    /**
     * Recupera a uri de download de um arquivo (Atráves da pk e id da mensagem)
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     * 
     * ---------- 
     * Esta requisição possui um redirecionamento de um endpoint para o outro, por isso foi necessário a utilização do fetch para que isso não aconteça automaticamente
     * Com a resposta obtida do fetch utiliza-se então o axios para salvar o arquivo através da URI retornada
     * O axios redirecionava automáticamente e dava erro na segunda requisição por causa dos headers
     * 
     * @param {string} idCanal 
     * @param {string} msgPk 
     * @param {string} msgId 
     * @returns {Promise<Response>}
     */
    mensagensGetFileUriByPkAndId: (idCanal, msgPk, msgId) => fetch(`${process.env.VUE_APP_API_KMSX}/v1/canais/${idCanal}/mensagens/pk/${msgPk}/msg/${msgId}/uri`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
    }),

    /**
     * Busca a mensagem através da sua pk e do seu id
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     *  
     * @param {string} idCanal 
     * @param {string} msgPk 
     * @param {string} msgId 
     * @returns {Promise<AxiosResponse<MsgComPkIndex>}
     */
    mensagensGetByPkAndId: (idCanal, msgPk, msgId) => KmsxServiceAdapter.get(`canais/${idCanal}/mensagens/pk/${msgPk}/msg/${msgId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }
    }),

    /**
     * Deleta a mensagem através da sua pk e do seu id
     * 
     * (PKs são divisões no armazenamento de mensagens. É: sequencial e muda de tempos em tempos. Para saber qual é o atual é só ver no objeto do canal 'currentMessagesPkIndex')
     *  
     * @param {string} idCanal 
     * @param {string} msgPk 
     * @param {string} msgId 
     * @returns {Promise<AxiosResponse<void>}
     */
    mensagensDeleteByPkAndId: (idCanal, msgPk, msgId) => KmsxServiceAdapter.delete(`canais/${idCanal}/mensagens/pk/${msgPk}/msg/${msgId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken()
        }
    }),

    /**
     * Recupera a uri da imagem do grupo
     * 
     * 
     * ---------- 
     * Esta requisição possui um redirecionamento de um endpoint para o outro, por isso foi necessário a utilização do fetch para que isso não aconteça automaticamente
     * Com a resposta obtida do fetch utiliza-se então o axios para salvar o arquivo através da URI retornada
     * O axios redirecionava automáticamente e dava erro na segunda requisição por causa dos headers
     * 
     * @param {Object} data 
     * @returns {Promise<Response>}
     */ 
    getImagemsUri: () => KmsxServiceAdapter.get(`canais/cover/imagens/uri/`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthUtils.getToken(),
        },
    }),
}

export default KonnectEnterpriseService;
