<template>
  <!-- Lista de mensagens -->
  <div
    ref="listaMsgs"
    v-if="dividirTela || !chatAtivo"
    :class="`msgs-container ${
      dividirTela && chatAtivo ? 'halfwidth' : 'fullwidth'
    }`"
  >
    <el-header>
      <div class="header-chat">
        <span>
            {{header}}
        </span>
      </div>
    </el-header>

    <el-divider />
    <div class="sidebarContatos">
      <el-aside v-if="windowWidth > 1000">
        <el-menu
          class="menu menu__externo"
          default-active="1"
          :isCollapse="true"
          :class="'menu-container'"
        >
          <div class="menu__externo__box">
            <el-menu-item @click="$router.push({name:'TelaInicial'})">
              <div class="menu-link" href="#">
                <i class="fas fa-comment-alt sideIcon" />
                <span>Chat</span>
              </div>
            </el-menu-item>
            <el-menu-item @click="$router.push({name:'Calendario'})">
              <div
                class="menu-link"
                href="#"
              >
                <i class="fas fa-calendar-alt c sideIcon" />
                <span>Calendário</span>
              </div>
            </el-menu-item>
            <el-menu-item>
              <div class="menu-link" @click="() => logout()">
                <i class="fas fa-sign-out-alt c sideIcon" />
                <span>Sair</span>
              </div>
            </el-menu-item>
          </div>
        </el-menu>
      </el-aside>

      <el-main class="main-panel">
        <slot name="side-menu"></slot>
      </el-main>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import AuthUtils from "../../utils/AuthUtils.js";
import SideSlider from "../../components/Konnect/SideSlider.vue";
import "@/assets/css/theme.css";

export default {
  name: "SideBar",
  components: {SideSlider},
  props: ["dividirTela", "chatAtivo", "header"],
  data() {
    return {
      isRole: AuthUtils.isRole,
      windowWidth: window.innerWidth,
    };
  },
    mounted(){
        if (!window.localStorage.token) this.$router.push("/login");
        this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    
    },
  computed: {
    nomeUsuarioLogado() {
      return JSON?.parse(window?.localStorage?.getItem("usuario"))?.nome;
    },
  },

  methods: {
    logout() {
      this.usuario = null;
      AuthUtils.doLogout();
      window.location.reload();
    },
    
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
};
</script>

<style scoped>

.msgs-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fafafa;
  border-right-color: #dcdfe6;
  border-right-style: solid;
  border-right-width: 1px;
  /* min-width: 10vw; */
  max-height: 100% !important;
  box-shadow: inset -7px 0 10px -7px #aaa;
}

>>> .header-chat {
  display: flex;
  margin: 0px 0px 0px 10px;
  height: 100%;
  align-items: center;
}

.el-aside {
  width: 80px !important;
  background-color: #fff;
  border-right: solid 1px #e6e6e6;
}

.sidebarContatos {
  display: flex;
  height: 100%;
}

.msgs-container .el-menu {
  border: none;
  background-color: unset !important;
}
.el-menu-item {
  color: var(--primeira-cor) !important;
  height: auto !important;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  padding-right: 20px !important;
  font-size: 10px;
}
.el-menu-item a:hover {
  color: #0060b3!important;
}
.el-menu-item a:hover {
  color: #0060b3!important;
}

.menu-link {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px !important;
}
.el-menu-item:focus, .el-menu-item:hover {
  background-color: var(--primeira-cor-claro);
}

>>> .fa-comment-alt,
>>> .c {
  color: var(--primeira-cor);
  font-size: 17px;
  margin-right: 10px;
}

span {
  display: block;
}





</style>