<template>
  <el-container>
    <el-dialog
      :close-on-click-modal="false"
      title="Editar Canal"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <div class="main-box">
        <label>Nome <b style="color: red">*</b></label>
        <el-input v-model="nome" maxlength="30" show-word-limit />
        <label style="margin-top: 20px">Descrição</label>
        <el-input
          maxlength="400"
          show-word-limit
          v-model="descricao"
          placeholder="Não há descrição"
          type="textarea"
        />
        <div v-if="data.tipo == 'EVENTO'" style="margin-top: 15px">
         <el-row :gutter="24">
          <el-col :span="12">
            <label>Data do incio do evento <b style="color: red">*</b></label>
            <el-date-picker
            v-model="dataDoEvento"
            type="datetime"
            placeholder=""
            format="dd/MM/yyyy HH:mm"
            :picker-options="pickerOptions"
            style="margin-bottom: 15px"
          />
          </el-col>
          <el-col :span="12">
            <label>Data do fim do evento <b style="color: red">*</b></label>
            <el-date-picker
            v-model="dataDeConclusao"
            type="datetime"
            placeholder=""
            format="dd/MM/yyyy HH:mm"
            :picker-options="pickerOptions"
            style="margin-bottom: 15px"
          />
          </el-col>
        </el-row>
          <label>Prioridade: {{ prioridade }}</label>
          <el-slider
            v-model="prioridade"
            :step="1"
            :max="10"
            style="padding: 0 10px; margin-bottom: 15px"
          />
          <label>Categoria</label>
          <el-select
            v-model="categoria"
            size="small"
            style="width: 100%"
            value-key="texto"
          >
            <el-option
              v-for="cat in categorias"
              :key="cat.texto"
              :label="cat.texto"
              :value="cat"
              class="option-categorias"
            >
              <span
                class="dot-categoria"
                :style="'background-color: ' + cat.cor"
              />
              <span>{{ cat.texto }}</span>
            </el-option>
          </el-select>
        </div>
        <el-button
          class="bt-salvar"
          size="small"
          @click="alterarCanal"
          :disabled="nome == ''"
        >
          S A L V A R
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import "../css/theme.css";
import KonnectService from "../services/KonnectEnterpriseService";

export default {
  name: "EditarCanal",
  props: { visible: Boolean, data: Object },
  components: {},

  data() {
    return {
      nome: "",
      descricao: "",
      prioridade: 1,
      dataDoEvento: "",
      dataDeConclusao: "",
      categoria: "",

      categorias: [
        {
          texto: "Lançamentos",
          cor: "#6550BE",
        },

        {
          texto: "Lembretes",
          cor: "#FF8C2F",
        },
        {
          texto: "Reuniões",
          cor: "#009F3A",
        },
        {
          texto: "Tasks",
          cor: "#FF30AE",
        },
      ],

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      selecionaSlider: false,
    };
  },

  mounted() {
    this.preencherCampos(this.data);
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },

    preencherCampos(d) {
      this.nome = d.nome;
      this.descricao = d.descricao;
      if (d.tipo == "EVENTO") {
        this.prioridade = d.prioridade;
        this.dataDoEvento = d.dataDoEvento;
        this.dataDeConclusao = d.dataDeConclusao;
        this.categoria = d.categoria;
      }
    },

    alterarCanal() {
      let reqBody = { nome: this.nome, descricao: this.descricao };
      if (this.data.tipo == "EVENTO")
        reqBody = {
          ...reqBody,
          prioridade: this.prioridade,
          dataDoEvento: this.dataDoEvento,
          dataDeConclusao: this.dataDeConclusao,
          categoria: this.categoria,
        };

      KonnectService.patchCanal(reqBody, this.data)
        .then((r) => {
          this.handleClose();
          this.$emit("alterar", r.data);
          this.$message({
            type: "success",
            message:
              this.data.tipo == "GRUPO" ? "Grupo alterado" : "Evento alterado",
          });
        })
        .catch((e) =>
          this.$notify({
            title: "Erro ao alterar grupo!",
            message: e.response.headers["x-reason"],
            type: "warning",
          })
        );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
>>> .main-box {
  display: flex;
  flex-direction: column;
}

>>> .el-dialog {
  margin: 15vh auto 50px !important;
}

>>> .bt-salvar {
  background-color: var(--segunda-cor);
  color: #fff;
  margin-top: 30px !important;
}
</style>
