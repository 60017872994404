<template>
  <el-dialog title="Novo Evento" :visible.sync="visible" :before-close="handleClose" :close-on-click-modal="false">
    <div class="main-box" v-loading="loading">
      <label>Nome do evento <b style="color: red">*</b></label>
      <el-input size="small" maxlength="30" show-word-limit v-model="reqBody.nome" />
      <div class="margin">
        <el-row :gutter="24">
          <el-col :span="12">
            <label>Data do incio do evento <b style="color: red">*</b></label>
            <el-date-picker v-model="reqBody.dataDoEvento" type="datetime" placeholder="" format="dd/MM/yyyy HH:mm"
              :picker-options="pickerOptions" />
          </el-col>
          <el-col :span="12">
            <label>Data do fim do evento <b style="color: red">*</b></label>
            <el-date-picker v-model="reqBody.dataDeConclusao" type="datetime" placeholder="" format="dd/MM/yyyy HH:mm"
              :picker-options="pickerOptions" />
          </el-col>
        </el-row>
      </div>
      <div class="margin">
        <label>Descrição</label>
        <el-input size="small" type="textarea" show-word-limit maxlength="400" v-model="reqBody.descricao" />
      </div>
      <div class="margin">
        <label>Prioridade</label>
        <el-slider v-model="reqBody.prioridade" :step="1" :max="10" style="padding: 0 10px" />
      </div>

      <div class="margin">
        <label>Categoria</label>
        <el-select v-model="reqBody.categoria" size="small" style="width: 100%" value-key="texto">
          <el-option v-for="cat in categorias" :key="cat.texto" :label="cat.texto" :value="cat" class="option-categorias">
            <span class="dot-categoria" :style="'background-color: ' + cat.cor" />
            <span>{{ cat.texto }}</span>
          </el-option>
        </el-select>
      </div>

      <div class="margin">
        <i class="fas fa-users" style="margin-right: 10px" />
        <label>Membros <b style="color: red">*</b></label>
      </div>

      <vue-perfect-scrollbar v-if="clientesSelecionados.length>0" class="users_selecionados" style="max-height: 100px; height: 100px;">
        <div class="lista_cliente_selecionado">
          <div v-for="(cs, index) in clientesSelecionados" class="cliente_selecionado">
            <el-avatar :icon="cs.iconeAtivo" 
            :style="hoverIcon.value ? cs.corIcone:`color: var(--segunda-cor); background-color: white;`" 
            @mouseover.native="hoverIcon = {value: true, indice: index }" @mouseleave.native="hoverIcon = {value: false, indice: index }" @click.native="excluirSelecao(cs, index)" class="cliente_selecionado_icon"></el-avatar>
            <span class="cliente_selecionado_nome">{{ cs.nome }}</span>
          </div>
        </div>
      </vue-perfect-scrollbar>

      <div style="margin-top: 10px;">
        <el-input v-model="usuarioBuscado" style="margin-top: 5px;width: 95%;"
          placeholder="Digite o nome ou email do Usuário a ser Buscado" />
      </div>

      <vue-perfect-scrollbar style="max-height: 300px; height: 300px">
        <el-checkbox v-if="!naoEncontrado" v-model="checkAllUsuarios" @change="handleCheckAllChange">
          Selecionar todos
        </el-checkbox>
        <h3 v-if="naoEncontrado">Nenhum usuário encontrado</h3> 
        <el-checkbox-group v-model="usuariosSelecionados">
          <el-checkbox v-for="(c, i) in clientes" :label="c" :key="i" @change="(value)=>addClientesSelecionados(value, i)">
            <el-avatar icon="el-icon-user-solid" style="color: white ; background-color: var(--segunda-cor);"/>

            <div class="cont-clientes">
              <div class="">
                <p class="usuario_info">{{ c.nome.toLowerCase() }}</p>

                <span class="usuario_info" style="text-transform: lowercase">{{ c.email }}</span>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </vue-perfect-scrollbar>

      <el-button size="small" class="bt-salvar" @click="criarEvento" :loading="loading" :disabled="reqBody.nome == null ||
        reqBody.dataDoEvento == null ||
        reqBody.dataDeConclusao == null ||
        usuariosSelecionados.length < 1
        ">
        C R I A R
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import "../css/theme.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import KonnectService from "../services/KonnectEnterpriseService";
import moment from "moment";

export default {
  name: "NovoEvento",
  props: { visible: Boolean, dataSelecionada: String },
  components: { VuePerfectScrollbar },

  data() {
    return {
      loading: false,
      clientes: [],
      constClientes: [],
      checkAllUsuarios: false,
      usuariosSelecionados: [],
      clientesSelecionados: [],

      usuarioBuscado: "",
      naoEncontrado: false,
      
      iconeAtivo: 'el-icon-user-solid',
      corIcone: "color: var(--segunda-cor); background-color: white;",
      hoverIcon: {value: false, indice: 0 },

      paginacao: {
        limit: 10,
        offset: 0,
        ffield: "id",
        order: "ASC",
        usaPaginacao: false,
        filterFields: "cpf,email",
        filterValue: "",
      },

      reqBody: {
        nome: null,
        descricao: null,
        prioridade: null,
        dataDoEvento: null,
        dataDeConclusao: null,
        outrosMembrosIds: [],
        categoria: null,
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },

      categorias: [
        {
          texto: "Lançamentos",
          cor: "#6550BE",
        },

        {
          texto: "Lembretes",
          cor: "#FF8C2F",
        },
        {
          texto: "Reuniões",
          cor: "#009F3A",
        },
        {
          texto: "Tasks",
          cor: "#FF30AE",
        },
      ],
    };
  },
  mounted() {
    this.listarClientes();
    this.reqBody.dataDoEvento = moment(this.dataSelecionada, "DD MMMM YYYY").toISOString();
  },
  watch: {
    usuarioBuscado() {
      this.naoEncontrado = false;
      this.gerarListaClientes(this.constClientes);
    },
    hoverIcon(){
      if (this.hoverIcon.value) {
        this.clientesSelecionados[this.hoverIcon.indice].iconeAtivo = "el-icon-remove";
        this.clientesSelecionados[this.hoverIcon.indice].corIcone = "color: red; background-color: white";
      }else{
        this.clientesSelecionados[this.hoverIcon.indice].iconeAtivo =  "el-icon-user-solid";
        this.clientesSelecionados[this.hoverIcon.indice].corIcone = "color: var(--segunda-cor); background-color: white;"
      }
    }
  },
  methods: {
    excluirSelecao(cs, index){
      this.usuariosSelecionados = this.usuariosSelecionados.filter(item => item !== this.clientesSelecionados[this.clientesSelecionados.indexOf(cs)]);
      this.clientesSelecionados = this.clientesSelecionados.filter(item => item !== this.clientesSelecionados[index]);
    },
    addClientesSelecionados(value, key){
      // Caso o Usuario esteja selecionado o adiciona ao comeco da lista de exibicao, caso contrario filtra apenas o que foi removido da lista
      if(value){
        this.clientesSelecionados.unshift(this.clientes[key]);
        // Adiciona icon e cor ao chip
        this.clientesSelecionados[0].corIcone = "color: var(--segunda-cor); background-color: white;";
        this.clientesSelecionados[0].iconeAtivo = "el-icon-user-solid";
      }else{
        this.clientesSelecionados = this.clientesSelecionados.filter(item => item !== this.clientes[key]);
      }
    },
    gerarListaClientes(data) {
      this.clientes = [];
      for (const usuario of data) {
        if (this.usuarioBuscado !== "") {
          this.checkAllUsuarios = false;
          // Compara os textos buscados com os dados do Cliente lsitado
          if (this.compareNormalizedText(usuario.nome, this.usuarioBuscado) || this.compareNormalizedText(usuario.email, this.usuarioBuscado)) {
            this.clientes.push(usuario);
          }
        } else {
          this.checkAllUsuarios = false;
          this.clientes.push(usuario);
        }
      }
      // Caso nao tenha nenhum item na lista exibe o label de nao encontrado
      if (Object.keys(this.clientes).length === 0) {
        this.naoEncontrado = true;
      }
    },
    compareNormalizedText(text, targetText){
      return this.normalizeText(text).match(this.normalizeText(targetText));
    },
    normalizeText(text){
      return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[&\/\\#,+()$~%.:'"*?<>{}-]/g, '');
    },
    handleClose() {
      this.$emit("closeModal", 2);
    },
    listarClientes() {
      this.loading = true;
      KonnectService.getClientes(this.paginacao)
        .then((r) => {
          this.gerarListaClientes(r.data);
          this.constClientes = r.data;
        })
        .catch((e) => console.log(e))
        .finally(() => (this.loading = false));
    },

    handleCheckAllChange(val) {
      if(val){
        this.usuariosSelecionados = this.clientes.filter(item => {
          // define o padrao de cor e icone e retorna o item como era antes
          item.corIcone = "color: var(--segunda-cor); background-color: white;";
          item.iconeAtivo = "el-icon-user-solid";
          return item;
        });
        this.clientesSelecionados = this.clientes;
      }else{
        this.usuariosSelecionados = [];
        this.clientesSelecionados = [];
      }
    },

    criarEvento() {
      this.loading = true;
      this.reqBody.outrosMembrosIds = [];
      this.usuariosSelecionados.forEach((u) =>
        this.reqBody.outrosMembrosIds.push(u.id)
      );

      KonnectService.postCanal("eventos", this.reqBody)
        .then((r) => {
          this.handleClose();
          this.$emit("abrirChat", r.data);
          this.$message({
            title: "Sucesso!",
            message: `Evento "${r.data.nome}" criado.`,
            type: "success",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Erro ao criar evento",
            message: e.response.headers["x-reason"],
            type: "warning",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
>>>p,
>>>label,
>>>span {
  font-weight: 400;
}

.usuario_info {
  margin-left: 10px;
}

>>>.main-box {
  text-align: start;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

>>>.el-dialog {
  margin: 0 auto 50px !important;
}

>>>.margin {
  margin-top: 25px;
}

>>>.fa-users {
  color: var(--segunda-cor);
}

>>>.bt-salvar {
  background-color: var(--segunda-cor) !important;
  color: #fff !important;
}

>>>.option-categorias {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

>>>.dot-categoria {
  float: left;
  width: 12px;
  margin: 0 7px;
  height: 12px;
  border-radius: 50px;
}

/* ELEMENT UI */
>>>.el-picker-panel__body,
>>>.el-tooltip__popper,
>>>.el-select-dropdown__list {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

>>>.el-dialog {
  margin-top: 5vh !important;
  width: 90% !important;
}

>>>.el-checkbox__label {
  display: flex !important;
  align-items: center !important;
}

>>>.el-checkbox {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0 !important;
  margin: 0 !important;
}

>>>.el-date-editor.el-input,
>>>.el-date-editor.el-input__inner {
  width: 100% !important;
}

>>>.el-slider__bar {
  background-color: var(--segunda-cor) !important;
}

>>>.el-slider__button {
  width: 10px !important;
  height: 10px !important;
  border: 2px solid var(--segunda-cor) !important;
  background-color: var(--segunda-cor) !important;
}
.lista_cliente_selecionado {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.cliente_selecionado {
  margin: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid;
  border-color: var(--segunda-cor);
  background-color: var(--segunda-cor);
  border-radius: 50px;
}

.cliente_selecionado_nome {
  padding: 2px;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
}

</style>
